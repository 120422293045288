import React, { useContext, useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Center,
  Image,
  Stack,
  Text,
  CopyButton,
  Paper,
  RingProgress,
  Loader,
  Group,
  MediaQuery,
} from "@mantine/core";
import { DataContext } from "../../../context/DataContext";
import { IconCopy, IconCheck, IconX } from "@tabler/icons-react";
import LaWalletImage from "../../../assets/LA_Wallet.jpeg";
import useStatusCheck from "../../../hooks/useStatusCheck";
export default function LAWallet() {
  const { settings, data } = useContext(DataContext);
  const totalTime = 5 * 60;
  const [timeLeft, setTimeLeft] = useState(totalTime);
  const [code, setCode] = useState(<Loader />);
  const [canRefresh, setCanRefresh] = useState(true);
  const [status, setStatus] = useState("PENDING");
  const [checkStatus, setCheckStatus] = useState(false);

  useStatusCheck(checkStatus, false, "idmax");

  const fetchCode = useCallback(async () => {
    data.provider = "LAWallet";
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/idmax",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      const data_returned = await response.json();
      setCode(data_returned.output.info.code);
      setCheckStatus(true);
      setTimeLeft(totalTime); // Reset the timer to 5 minutes
    } catch (error) {
      console.error("Error fetching code:", error);
    }
  }, [settings.request_id]);

  useEffect(() => {
    fetchCode();
  }, [fetchCode]);

  useEffect(() => {
    let interval;
    if (timeLeft != null) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timeLeft]);

  const handleRefreshClick = () => {
    if (canRefresh) {
      fetchCode();
      setCanRefresh(false); // Prevent further refreshes
    }
  };

  const timerDisplay =
    timeLeft > 0 ? (
      <Text size="lg">
        {Math.floor(timeLeft / 60)}:
        {timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
      </Text>
    ) : canRefresh ? (
      <Text size="lg">00:00</Text>
    ) : (
      <Text size="lg">00:00</Text>
    );

  if (status !== "PENDING") {
    return (
      <>
        <Stack mt="xl">
          <Group noWrap>
            {status === "PASS" ? (
              <IconCheck color="green" size={50} />
            ) : (
              <IconX color="red" size={50} />
            )}

            <Text fz="xl" fw={700}>
              {status === "PASS"
                ? "You have been succesfully verified."
                : "We were unable to verify you"}
            </Text>
          </Group>
          <Group mt="xl">
            <Loader />
            <Text>Completing the process...</Text>
          </Group>
        </Stack>
      </>
    );
  }

  return (
    <Stack spacing="xl">
      <Box>
        <Stack spacing={0}>
          <Text c="dimmed" fz="md">
            Verify with
          </Text>
          <Text fz="xl" fw={700}>
            LA Wallet
          </Text>
        </Stack>

        <Stack spacing={40} mt={20}>
          <Text fz="lg" fw={500}>
            Enter this code into your LA Wallet App to verify your age.
          </Text>
          <Center>
            <RingProgress
              size={80}
              thickness={5}
              roundCaps
              sections={[
                {
                  value: timeLeft != null ? (timeLeft / (5 * 60)) * 100 : 0,
                  color:
                    timeLeft != null
                      ? timeLeft > totalTime * 0.5
                        ? "blue"
                        : timeLeft > totalTime * 0.2
                        ? "orange"
                        : "red"
                      : "gray",
                },
              ]}
              label={<Center>{timerDisplay}</Center>}
            />
          </Center>
          <Paper
            mt={0}
            withBorder
            p="xl"
            style={{ backgroundColor: "#f0eded", textAlign: "center" }}
          >
            {timeLeft > 0 ? (
              <Text fz={40} fw={700}>
                {code}
              </Text>
            ) : (
              <>
                {canRefresh ? (
                  <Stack>
                    <Text>Code is expired</Text>
                    <Button onClick={handleRefreshClick}>Get New Code</Button>
                  </Stack>
                ) : (
                  <Text>
                    You have used all of your codes. Please go back and try a
                    different method.
                  </Text>
                )}
              </>
            )}
          </Paper>
          <Stack spacing={5}>
            <Group position="center">
              <Loader size={"sm"} />
              <Text ta="center" c="dimmed" fz="sm">
                Checking your status.
              </Text>
            </Group>
            <Text ta="center" c="dimmed" fz="sm">
              Please enter the code into your LA Wallet App.
            </Text>
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={(theme) => ({
          display: "none",
          "@media (max-width: 40em)": {
            display: "inline-block",
          },
        })}
      >
        {/*
        <CopyButton value={code} timeout={2000}>
          {({ copied, copy }) => (
            <Button
              fullWidth
              style={{ alignSelf: "center" }}
              size="lg"
              leftIcon={
                copied ? (
                  <IconCheck size="2rem" stroke={1} />
                ) : (
                  <IconCopy size="2rem" stroke={1} />
                )
              }
              onClick={copy}
            >
              {copied ? "CODE COPIED" : "COPY CODE"}
            </Button>
          )}
        </CopyButton>
         */}
      </Box>
    </Stack>
  );
}
