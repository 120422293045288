import React, { useEffect, useState, useContext } from "react";
import { usePlaidLink } from "react-plaid-link";
import { DataContext } from "../../../context/DataContext";
import { Loader, Container, Flex, Paper, Text, Group } from "@mantine/core";
import useStatusCheck from "../../../hooks/useStatusCheck";
import CenterLoadingOverlay from "../../../components/center-loading-overlay";
const Plaid = ({ onClose }) => {
  const [token, setToken] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [template, setTemplate] = useState([]);
  const [closed, setClosed] = useState(false);
  const [done, setDone] = useState(false);
  const [linkSessionID, setLinkSessionID] = useState(null);
  const { settings, data } = useContext(DataContext);
  useStatusCheck(done, false, "idmax");
  // Initialize Plaid Link with an empty token which will be replaced once the token is fetched.
  const { open, ready } = usePlaidLink({
    token,
    onSuccess: (public_token, metadata) => {
      setLinkSessionID(metadata.link_session_id);
    },
    onExit: (error, metadata) => {
      //console.log("exit or error", error, metadata);
      //fires when the x is clicked
      setClosed(true);
    },
    onEvent: (eventName, metadata) => {
      //console.log("event", eventName, metadata);
      if (eventName === "IDENTITY_VERIFICATION_CLOSE_UI") {
        setLoaded(false);
        setDone(true);
      }
      // Optionally handle the event.
      template.info.identuty_verification_id =
        metadata.identity_verification_id;
    },
  });

  useEffect(() => {
    if (!token && !loaded) {
      data.provider = "PLAID";
      fetch(
        process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/idmax",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((data_returned) => {
          setTemplate(data_returned.continuations.info.template);
          setToken(data_returned.continuations.info.template.info.link_token); // Set the token that we'll use for Plaid Link
          setLoaded(true); // Indicate that the token loading process is complete
        })
        .catch((error) => {
          console.error("Error fetching the link token:", error);
        });
    }
  }, [token, loaded, settings.request_id]); // Add dependencies here

  // Automatically open Plaid Link if ready and the token has been loaded
  useEffect(() => {
    if (ready && token) {
      open();
    }
  }, [ready, token, open]);

  useEffect(() => {
    if (closed) {
      window.top.postMessage("CLOSE", "*");
    }
  }, [closed]);

  useEffect(() => {
    if (linkSessionID) {
      fetchStatus();
    }
  }, [linkSessionID]);

  const fetchStatus = async () => {
    //setLoading(true);

    data.isIDMaxContinue = true;
    data.template = template;
    data.template.info.identity_verification_id = linkSessionID;

    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/idmax",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data_returned = await response.json();

      //setStatus(data_returned.status);
    } catch (error) {
      console.error("Error fetching status:", error);
      //setStatus("ERROR"); // or a more descriptive error message
    } finally {
      //setLoading(false);
    }
  };

  // Render a loading indicator if we are waiting for the token
  if (!loaded) {
    return (
      <CenterLoadingOverlay
        content={
          <>
            <Group position="center">
              <Loader />
            </Group>
          </>
        }
      />
    );
  }

  // Render nothing if loaded but Plaid Link has not yet been opened
  return null;
};

export default Plaid;
