import React, { useState, useEffect, useContext } from "react";
import { NativeSelect, Stack, Grid, Group, Text } from "@mantine/core";
import FloatingLabelInput from "./floating-label-input";
import { DataContext } from "../context/DataContext";

const AddressInput = ({ onAddressChange, value, ...props }) => {
  const { text, data } = useContext(DataContext);
  const [address, setAddress] = useState({
    addr: data?.addr || "",
    city: data?.city || "",
    state: data?.state || "",
    zip: data?.zip || "",
  });

  useEffect(() => {
    setAddress({
      addr: data?.addr || "",
      city: data?.city || "",
      state: data?.state || "",
      zip: data?.zip || "",
    });
  }, []);

  useEffect(() => {
    onAddressChange(address);
  }, [address]);

  const handleInputChange = (field, value) => {
    setAddress((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const states = [
    { value: "", label: "" },
    { value: "AL", label: "AL" },
    { value: "AK", label: "AK" },
    { value: "AZ", label: "AZ" },
    { value: "AR", label: "AR" },
    { value: "CA", label: "CA" },
    { value: "CO", label: "CO" },
    { value: "CT", label: "CT" },
    { value: "DE", label: "DE" },
    { value: "FL", label: "FL" },
    { value: "GA", label: "GA" },
    { value: "HI", label: "HI" },
    { value: "ID", label: "ID" },
    { value: "IL", label: "IL" },
    { value: "IN", label: "IN" },
    { value: "IA", label: "IA" },
    { value: "KS", label: "KS" },
    { value: "KY", label: "KY" },
    { value: "LA", label: "LA" },
    { value: "ME", label: "ME" },
    { value: "MD", label: "MD" },
    { value: "MA", label: "MA" },
    { value: "MI", label: "MI" },
    { value: "MN", label: "MN" },
    { value: "MS", label: "MS" },
    { value: "MO", label: "MO" },
    { value: "MT", label: "MT" },
    { value: "NE", label: "NE" },
    { value: "NV", label: "NV" },
    { value: "NH", label: "NH" },
    { value: "NJ", label: "NJ" },
    { value: "NM", label: "NM" },
    { value: "NY", label: "NY" },
    { value: "NC", label: "NC" },
    { value: "ND", label: "ND" },
    { value: "OH", label: "OH" },
    { value: "OK", label: "OK" },
    { value: "OR", label: "OR" },
    { value: "PA", label: "PA" },
    { value: "RI", label: "RI" },
    { value: "SC", label: "SC" },
    { value: "SD", label: "SD" },
    { value: "TN", label: "TN" },
    { value: "TX", label: "TX" },
    { value: "UT", label: "UT" },
    { value: "VT", label: "VT" },
    { value: "VA", label: "VA" },
    { value: "WA", label: "WA" },
    { value: "WV", label: "WV" },
    { value: "WI", label: "WI" },
    { value: "WY", label: "WY" },
  ];

  return (
    <>
      <Group>
        <Text fz="xs" c="dimmed">
          {text.general.address}
        </Text>
      </Group>

      <Stack spacing="xs">
        {props.error && (
          <Text fz="xs" c="red">
            Address is required
          </Text>
        )}
        <FloatingLabelInput
          placeholder=""
          label="Street Address"
          value={address.addr}
          onChange={(e) => handleInputChange("addr", e.currentTarget.value)}
        />

        <Grid gutter={8}>
          <Grid.Col span={5}>
            <FloatingLabelInput
              placeholder=""
              label="City"
              value={address.city}
              onChange={(e) => handleInputChange("city", e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <NativeSelect
              size="lg"
              data={states}
              label="State"
              placeholder=""
              onChange={(e) =>
                handleInputChange("state", e.currentTarget.value)
              }
              value={address.state}
              styles={{
                input: {
                  fontSize: "18px",
                  paddingTop: "5px",
                },
                label: {
                  position: "absolute",
                  pointerEvents: "none",
                  fontSize: "11px",
                  paddingLeft: "10px",
                  paddingTop: "5px",
                  zIndex: "1",
                  color: "#868e96",
                },
                root: {
                  position: "relative",
                },
              }}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <FloatingLabelInput
              placeholder="text.general.zip"
              label="Zip"
              value={address.zip}
              onChange={(e) => handleInputChange("zip", e.currentTarget.value)}
              maxLength={5}
              minLength={5}
              type="number"
              pattern="\d*"
            />
          </Grid.Col>
        </Grid>
      </Stack>
    </>
  );
};

export default AddressInput;
