import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Group,
  Stack,
  Text,
  Image,
  Box,
  Loader,
  Flex,
  Center,
  CopyButton,
  ActionIcon,
} from "@mantine/core";
import { IconBolt, IconHeart, IconHeartFilled } from "@tabler/icons-react";
import ClearIcon from "../../../assets/clear_logo_midnight.svg";
import ClearIconWhite from "../../../assets/clear_logo_white.svg";
import ClearFullLogo from "../../../assets/clear_full_logo.svg";
import { DataContext } from "../../../context/DataContext";
import { isMobile } from "react-device-detect";
import QRCode from "qrcode.react";
import useStatusCheck from "../../../hooks/useStatusCheck";
const ClearStart = () => {
  const { settings, data } = useContext(DataContext);
  const [url, setUrl] = useState(null);
  useStatusCheck(true, false, "idmax");

  const handleVerifyClick = () => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (url) return;
    const fetchUrl = async () => {
      // setLoading(true);
      //data.phone = "2033954438";
      data.provider = "CLEAR";
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/idmax",
          {
            method: "POST",
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data_returned = await response.json();
        //console.log("data", data_returned);
        setUrl(data_returned.continuations.info.template.info.request_url);
      } catch (error) {
        console.error("Error fetching status:", error);
        //setStatus("ERROR"); // or a more descriptive error message
      } finally {
        //setLoading(false);
      }
    };

    fetchUrl();
  }, [url]);

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // This will push the button to the bottom
          height: "100%", // This should take the full height of its parent
        }}
      >
        <Box>
          <Stack>
            <Center>
              <Image src={ClearFullLogo} width={150} />
            </Center>
            <Center mt="lg">
              <Stack>
                <Group noWrap>
                  <IconBolt size={20} />
                  <Text fz="md">Fast-track with existing CLEAR account.</Text>
                </Group>
                <Group noWrap>
                  <Image src={ClearIcon} width={20} />
                  <Text fz="md">Verify swiftly with CLEAR for new users.</Text>
                </Group>
                <Group noWrap>
                  <IconHeart size={20} />
                  <Text fz="md">Trusted by millions.</Text>
                </Group>
              </Stack>
            </Center>
          </Stack>
          {!isMobile && (
            <Stack align="center" mt="xl">
              <Box
                style={{
                  height: 200,
                  width: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {url ? <QRCode value={url} size={200} /> : <Loader />}
              </Box>

              <Stack spacing={5}>
                <Text ta="center" fw={700}>
                  Scan to continue
                </Text>
              </Stack>
            </Stack>
          )}
        </Box>
        <Box>
          {!isMobile ? (
            <Group position="center" mt="md">
              <Loader size="sm" />
              <Text ta="center" c="dimmed" fz="sm">
                Checking your status...
              </Text>
            </Group>
          ) : !url ? (
            <Center mt="lg">
              <Group>
                <Loader />
                <Text>Getting ready...</Text>
              </Group>
            </Center>
          ) : (
            <Button
              size="lg"
              mt="lg"
              leftIcon={<Image src={ClearIconWhite} width={24} />}
              style={{
                backgroundColor: "#041A55",
              }}
              onClick={handleVerifyClick}
              fullWidth
            >
              Verify with CLEAR
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ClearStart;
