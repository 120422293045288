import { useEffect, useContext } from "react";
import { DataContext } from "../context/DataContext";
import { useLocation } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";
const useStatusCheck = (triggerCondition, isHandoff, currentPath) => {
  const location = useLocation();
  const interval = 2000;
  const { settings, dispatch, routeSequence } = useContext(DataContext);

  useEffect(() => {
    if (triggerCondition) {
      let isLastRoute =
        routeSequence.length > 0 &&
        (currentPath === "dcams/upload" ||
          (currentPath === "idmax" && routeSequence.length === 1) ||
          currentPath === routeSequence[routeSequence.length - 1].path);

      const requestBody = {
        request_id: settings.request_id,
        ...(isHandoff ? {} : { isLastRoute, currentPath }),
      };
      let intervalId = setInterval(() => {
        fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v3/stable/api/frontend/statusCheck`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
          }
        )
          .then((res) => res.json())
          .then((result) => {
            if (result && result.status) {
              if (result.status === "NEW") {
                return;
              }

              if (result.status === "PASS") {
                dispatch({ type: "SET_STATUS", payload: "PASS" });
              } else if (
                result.status !== "PASS" &&
                result.enhanced_next_document === "false"
              ) {
                if (!isHandoff && currentPath !== "idmax") {
                  notifications.show({
                    id: "modular-failure",
                    autoClose: 4000,
                    title: "Whoops! You've failed the previous verification",
                    message: settings.journey?.settings?.isModular
                      ? "Please select another option below"
                      : "Let's try another way",
                    color: "red",
                    icon: <IconX />,
                    className: "error-message",
                    //style: { backgroundColor: "rgba(255, 0, 0, 0.5)" },
                    //sx: { border: "1px solid #fafafa" },
                    //loading: false,
                  });
                }

                if (settings.journey?.settings?.isModular) {
                  const updatedRouteSequence = routeSequence.filter(
                    (route) => route.path !== currentPath
                  );
                  dispatch({ type: "SET_SELECTED_COMPONENT", payload: "" });
                  dispatch({
                    type: "SET_MODULAR_FAILED_AT_LEAST_ONE",
                    payload: true,
                  });
                  dispatch({
                    type: "SET_ROUTE_SEQUENCE",
                    payload: updatedRouteSequence,
                  });
                }
                dispatch({ type: "SET_STATUS", payload: "PENDING" });
                dispatch({ type: "TRIGGER_NAVIGATION", payload: true });
              } else if (
                result.status !== "PASS" &&
                result.detail !== "MORE INFORMATION IS REQUIRED" &&
                result.enhanced_next_document === "true"
              ) {
                dispatch({ type: "SET_STATUS", payload: "FAIL" });
              }
              if (!isHandoff || result.enhanced_next_document === "true") {
                dispatch({
                  type: "SET_IS_CURRENT_COMPONENT_COMPLETE",
                  payload: true,
                });
              }
            }
          })
          .catch((error) =>
            console.error("Something went wrong while polling:", error)
          );
      }, interval);

      return () => clearInterval(intervalId);
    }
  }, [triggerCondition, settings.request_id, dispatch, isHandoff]);
};

export default useStatusCheck;
