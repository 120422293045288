// src/RootComponent.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import App from "./App";
import { DataProvider } from "./context/DataContext";
import NotFound from "./pages/not-found";
import SubRoutes from "./routes";
import {
  FpjsProvider,
  FingerprintJSPro,
} from "@fingerprintjs/fingerprintjs-pro-react";
const AppWithData = () => {
  const { request_id } = useParams();
  return (
    <FpjsProvider
      loadOptions={{
        apiKey: "U1OoU5FUoWuUW1gJvyL3",
        //apiKey: "U1OoU5FUoWuUW1gJv",
        endpoint: [
          "https://fingerprint.dcams.app",
          FingerprintJSPro.defaultEndpoint,
        ],
        scriptUrlPattern: [
          "https://fingerprint.dcams.app/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
          FingerprintJSPro.defaultScriptUrlPattern,
        ],
      }}
    >
      <DataProvider request_id={request_id}>
        <App routes={<SubRoutes />} />
      </DataProvider>
    </FpjsProvider>
  );
};

const RootComponent = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:request_id/*" element={<AppWithData />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default RootComponent;
