import React, { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import IDScan from "../assets/id-scan-loading.json";
import { Text, Stack, Progress } from "@mantine/core";

const getAnimationData = (type) => {
  switch (type) {
    case "documentVerification":
      return { animation: IDScan, message: "Checking your document..." };
    case "documentVerificationLongDuration":
      return {
        animation: IDScan,
        message: "This may take up to 90 seconds to complete. Please wait...",
        longDuration: true,
      };
    // Add more cases as needed
    default:
      return { animation: IDScan, message: "Loading..." };
  }
};

export default function VerificationLoadingScreen({ type }) {
  const { animation, message, longDuration } = getAnimationData(type);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (longDuration) {
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 1.6667; // 100% / 60 seconds
        });
      }, 1000); // update every second

      return () => clearInterval(interval);
    }
  }, [longDuration]);

  return (
    <Stack spacing={15}>
      <Lottie
        animationData={animation}
        loop={true}
        play
        style={{ height: 200 }}
      />
      {longDuration && <Progress value={progress} animate />}
      <Text c="dimmed" align="center">
        {message}
      </Text>
    </Stack>
  );
}
